<template>
  <div class="pb-2 pt-4">
    <v-row>
      <component
        :is="item"
        v-for="(item, index) in abuseRecommenderFilters"
        :key="index"
        v-model="filters[item]"
        :label="attributeLabels[item]"
        @remove="removeFilter(item)"
      ></component>
      <v-col
        :sm="isEmpty(availableFilters) ? 6 : 12"
        :md="isEmpty(availableFilters) ? 4 : 8"
        :lg="isEmpty(availableFilters) ? 2 : 4"
        class="align-self-center"
      >
        <v-menu
          v-if="!isEmpty(availableFilters)"
          top
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              outlined
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              <v-icon x-small>mdi-plus</v-icon>
              {{ $t("addFilter") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, index) in availableFilters"
                :key="index"
              >
                <v-list-item-title @click="addFilter(item.key)">{{
                  item.label
                }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn x-small outlined color="red" dark @click="resetFilters">
          {{ $t("resetFilters") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters, mapState } from "vuex";
import { SET_FILTER } from "@/store/actions/recommender";
import { SCOPE_YOUTUBE } from "@/store/modules/service/getters";
import { availableAbuseRecommenderFilters } from "@/store/modules/persistentStorage/state";
import { defaultFilters } from "@/store/modules/recommender/abuse/state";

import {
  ADD_ABUSE_RECOMMENDER_FILTER,
  REMOVE_ABUSE_RECOMMENDER_FILTER,
  SET_DEFAULT_ABUSE_RECOMMENDER_FILTER,
  SET_ABUSE_RECOMMENDER_FILTER,
} from "@/store/actions/persistentStorage";

import TagSearch from "@/views/search-parts/TagSearch";
import TypeSearch from "@/views/search-parts/TypeSearch";
import StageSearch from "@/views/search-parts/StageSearch";
import GenderSearch from "@/views/search-parts/GenderSearch";
import AgeGroupSearch from "@/views/search-parts/AgeGroupSearch";
import LanguageSearch from "@/views/search-parts/LanguageSearch";

export default {
  name: "AbuseRecommenderSearch",
  components: {
    tagId: TagSearch,
    type: TypeSearch,
    stage: StageSearch,
    gender: GenderSearch,
    ageGroup: AgeGroupSearch,
    language: LanguageSearch,
  },
  data: function () {
    return {
      closeOnContentClick: true,
      attributeLabels: {
        gender: this.$t("account.attribute.gender"),
        type: this.$t("account.attribute.type"),
        ageGroup: this.$t("account.attribute.age"),
        language: this.$t("account.attribute.language"),
        tagId: this.$t("account.attribute.tags"),
        stage: this.$t("account.attribute.stage"),
      },
      //List of selected related items
      //Filter values status
      isTagLoading: false,
      //Filter values
      tags: [],
      //Filter entered value
      tagSearch: "",
    };
  },
  computed: {
    ...mapGetters("abuse", [
      "availableScopes",
      "availableSegments",
      "formattedFilters",
    ]),
    ...mapGetters("account/donor", [
      "availableGenders",
      "availableLanguages",
      "availableStages",
      "availableAges",
      "availableLanguageLabels",
    ]),
    ...mapState("abuse", ["filters"]),
    ...mapState("persistentStorage", ["abuseRecommenderFilters"]),
    isYoutube: function () {
      return this.filters.target === SCOPE_YOUTUBE;
    },
    availableFilters: function () {
      let component = this;
      let result = [];

      const filtersToSelect = _.difference(
        availableAbuseRecommenderFilters,
        this.abuseRecommenderFilters
      );

      filtersToSelect.forEach(function (filter) {
        result.push({
          key: filter,
          label: _.get(component.attributeLabels, filter),
        });
      });
      return result;
    },
  },
  watch: {
    selectedFilters: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        query.selectedFilters = this.abuseRecommenderFilters;
        this.$router
          .push({ name: this.currentRouteName, query })
          .catch(() => {});
      },
      deep: true,
    },
  },
  methods: {
    isSelectedFilter: function (filter) {
      return this.abuseRecommenderFilters.indexOf(filter) > -1;
    },
    addFilter: function (filter) {
      this.$store.commit(
        `persistentStorage/${ADD_ABUSE_RECOMMENDER_FILTER}`,
        filter
      );
    },
    removeFilter: function (filter) {
      this.$store.commit(
        `persistentStorage/${REMOVE_ABUSE_RECOMMENDER_FILTER}`,
        filter
      );
      this.filters[filter] = null;
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    resetFilters: function () {
      this.$store.commit(
        `persistentStorage/${SET_DEFAULT_ABUSE_RECOMMENDER_FILTER}`
      );
      this.$store.commit(
        "abuse/" + SET_FILTER,
        _.merge({}, defaultFilters, { target: this.filters.target })
      );
    },
  },
  mounted: function () {
    let vm = this;
    if (!_.isEmpty(vm.$route.query) && vm.$route.query["selectedFilters"]) {
      let selectedFilters = vm.$route.query["selectedFilters"];
      if (!(selectedFilters instanceof Array)) {
        selectedFilters = [selectedFilters];
      }
      this.$store.commit(
        `persistentStorage/${SET_ABUSE_RECOMMENDER_FILTER}`,
        _.merge([], selectedFilters)
      );
    }
    if (!_.isEmpty(vm.$route.query)) {
      _.forIn(vm.$route.query, function (value, filter) {
        if (!vm.isSelectedFilter(filter)) {
          vm.addFilter(filter);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
