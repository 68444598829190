<template>
  <div>
    <v-row class="d-flex justify-end">
      <v-col>
        <h1>{{ $t("recommender.abuse.listTitle") }}</h1>
      </v-col>
      <v-col cols="4" class="text-end">
        <v-btn
          small
          color="primary"
          :title="
            $t('recommender.addSettingsFor', {
              scope: getServiceName({ scope: filters.target }),
            })
          "
          @click="editItem(null)"
        >
          {{ $t("recommender.addSettings") }}
        </v-btn>
      </v-col>
    </v-row>
    <abuse-recommender-search></abuse-recommender-search>
    <v-divider class="mt-5"></v-divider>
    <v-tabs
      v-model="selectedTarget"
      background-color="grey lighten-4"
      class="mt-2"
    >
      <v-tab
        v-for="item in availableScopes"
        :key="item"
        :title="
          $t('recommender.settingsFor', {
            target: getServiceName({ scope: item }),
          })
        "
      >
        {{ getServiceName({ scope: item }) }}
      </v-tab>
    </v-tabs>

    <div class="mt-3">
      <!-- Data loader -->
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div>
        <!--    Data table     -->
        <v-data-iterator
          v-if="!dataLoading"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="items"
          :no-data-text="$t('no-data')"
        >
          <template v-slot:header>
            <!--    Table header begin -->
            <div
              v-if="totalCount > 0"
              class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0"
            >
              <v-row class="grey--text body-2">
                <v-col cols="2" lg="3" class="align-self-end">
                  {{ $t("recommender.segmentSettings") }}
                </v-col>
                <v-col lg="5" class="align-self-end">
                  {{ $t("recommender.limitSettings") }}
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("recommender.abuse.attributes.status") }}
                </v-col>
                <v-col lg="2" class="align-self-end">
                  {{ $t("recommender.abuse.attributes.updatedAt") }}
                </v-col>
              </v-row>
            </div>
            <!--    Table header end -->
          </template>
          <template v-slot:default="props">
            <abuse-recommender-item
              v-for="(item, i) in props.items"
              :key="i"
              :settings="item"
              @editInfo="editItem(item)"
              @deleteItem="deleteConfirmation(item)"
              @activateItem="confirmActivation(item)"
            ></abuse-recommender-item>
          </template>
        </v-data-iterator>
        <!--    TTable pagination -->
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2">
            {{
              $t("paginationSummary", {
                from: offset + 1,
                to: maxItem,
                totalCount: totalCount,
              })
            }}
          </span>
        </div>
      </div>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>

    <!--  Setting form dialog    -->
    <v-dialog v-model="editDialog" max-width="700px">
      <abuse-recommender-form
        v-if="editDialog"
        :item="selectedItem"
        :scope="filters.target"
        :segment="selectedSegment"
        @close="closeEditDialog"
        @submitted="
          closeEditDialog();
          loadItems();
        "
      ></abuse-recommender-form>
    </v-dialog>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("deleteConfirmation") }}
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          {{ $t("recommender.deleteConfirmation") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteItem(selectedItem)">
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedItem"
      v-model="activateConfirmDialog"
      max-width="450px"
    >
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("activationConfirmation") }}
          <v-btn icon color="white" @click="activateConfirmDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          {{ $t("recommender.activationConfirmation") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="activateConfirmDialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn text color="blue darken-1" @click="activateItem(selectedItem)">
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import BaseListPage from "@/views/base/BaseListPage";

import AbuseRecommenderForm from "@/views/recommender/abuse/AbuseRecommenderForm";
import AbuseRecommenderItem from "@/views/recommender/abuse/list/AbuseRecommenderItem";
import AbuseRecommenderSearch from "@/views/recommender/abuse/list/AbuseRecommenderSearch";

import { mapGetters, mapState } from "vuex";
import { FETCH_SERVICES } from "@/store/actions/service";

import {
  defaultFilters,
  multipleFilters,
} from "@/store/modules/recommender/abuse/state";

import {
  SET_FILTER,
  FETCH_ABUSE_SETTINGS,
  DELETE_ABUSE_SETTINGS,
  ACTIVATE_ABUSE_SETTINGS,
} from "@/store/actions/recommender";

export default {
  name: "AbuseRecommenderList",
  extends: BaseListPage,
  components: {
    AbuseRecommenderForm,
    AbuseRecommenderItem,
    AbuseRecommenderSearch,
  },
  data: function () {
    return {
      selectedTarget: 0,
      deleteDialog: false,
      activateConfirmDialog: false,
      defaultFilters: _.merge({}, defaultFilters),
      multipleFilters,
    };
  },
  computed: {
    ...mapState("abuse", ["pagination", "filters", "sort", "emptyFilters"]),
    ...mapGetters("service", ["scopes", "getServiceName"]),
    ...mapGetters("abuse", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "availableScopes",
      "availableSegments",
      "formattedFilters",
    ]),
    selectedSegment: function () {
      let component = this;
      let selectedSegment = {};
      this.availableSegments.forEach(function (segment) {
        selectedSegment[segment] = component.filters[segment];
      });
      return selectedSegment;
    },
  },
  watch: {
    selectedTarget: function () {
      if (this.filters) {
        this.filters.target = this.availableScopes[this.selectedTarget];
      }
    },
  },
  methods: {
    applyFilters: function (data) {
      this.$store.commit("abuse/" + SET_FILTER, data.data);
    },
    loadServices: function () {
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
    loadItems: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("abuse/" + FETCH_ABUSE_SETTINGS)
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    deleteConfirmation: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.dataLoading = true;
      this.$store
        .dispatch("abuse/" + DELETE_ABUSE_SETTINGS, item)
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: this.$t("recommender.deleteSettings"),
                text: this.$t("recommender.deleteSettingsSuccess"),
              });
              this.dataLoading = false;
              this.selectedItem = null;
              this.deleteDialog = false;
              this.loadItems();
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    confirmActivation: function (item) {
      this.selectedItem = item;
      this.activateConfirmDialog = true;
    },
    activateItem: function (settings) {
      this.confirmDialog = false;
      this.$store
        .dispatch("abuse/" + ACTIVATE_ABUSE_SETTINGS, { settings })
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: this.$t("recommender.syncSettings"),
                text: this.$t("recommender.syncSettingsSuccess"),
              });
              this.loadItems();
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    if (!this.applyRouteParams()) {
      this.loadItems();
    }
    this.selectedTarget = this.filters.target
      ? this.availableScopes.indexOf(this.filters.target)
      : 0;
    this.loadServices();
  },
  sockets: {
    abuseRecommender: function (message) {
      this.$notify({
        group: "error",
        type: "error",
        title: this.$t("syncError"),
        text: message,
      });
    },
  },
};
</script>

<style scoped>
.theme--light.v-divider {
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
}
</style>
